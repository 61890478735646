<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      v-if="!$route.params.id"
      label="Category"
      @click.native.stop
    >
      <v-select
        v-model="announcementCategoryValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="announcementCatOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>

    <b-form-group
      label="Created Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="startDateValue"
          class="form-control"
          placeholder="Select date range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-form-group
      v-if="!($route.params.type && $route.params.type == 'pending')"
      label="Status"
    >
      <b-form-checkbox
        v-for="option in statusOptions"
        :key="option.code"
        v-model="statusFilterValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group label="Display in Highlights">
      <b-form-checkbox
        v-for="option in highlightOptions"
        :key="option.code"
        v-model="highlightFilterValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>

    <div class="d-flex mt-1">
      <b-button
        variant="outline-primary"
        size="md"
        class="mr-1 w-100"
        @click="clearFilter"
      >
        Clear All
      </b-button>
      <b-button
        variant="primary"
        size="md"
        class="w-100"
        @click="applyFilter"
      >
        Apply Filter
      </b-button>
    </div>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    flatPickr,
    vSelect,
  },
  props: {
    startDate: {
      type: [String, null],
      default: null,
    },
    announcementCategory: {
      type: [Array, null],
      default: () => [],
    },
    statusFilter: {
      type: [Array, null],
      default: () => [],
    },
    highlightFilter: {
      type: [Array, null],
      default: () => [],
    },
    announcementCatOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      startDateValue: this.startDate,
      announcementCategoryValue: JSON.parse(JSON.stringify(this.announcementCategory)) || [],
      statusFilterValue: JSON.parse(JSON.stringify(this.statusFilter)) || [],
      highlightFilterValue: JSON.parse(JSON.stringify(this.highlightFilter)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
      statusOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Pending Verification', code: 'pending' },
      ],
      highlightOptions: [
        { title: 'Yes', code: 'yes' },
        { title: 'No', code: 'no' },
      ],
    }
  },
  watch: {
    startDate: {
      immediate: true,
      handler(val) {
        this.startDateValue = val
      },
    },
    announcementCategory: {
      immediate: true,
      handler(val) {
        this.announcementCategoryValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    highlightFilter: {
      immediate: true,
      handler(val) {
        this.highlightFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    showAgainDropdown() {
      if (this.startDateValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:startDate', this.startDateValue)
      this.$emit('update:announcementCategory', this.announcementCategoryValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:highlightFilter', this.highlightFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.startDateValue = ''
      this.statusFilterValue = []
      this.announcementCategoryValue = []
      this.statusFilterValue = []
      this.highlightFilterValue = []
      this.$emit('update:startDate', this.startDateValue)
      this.$emit('update:announcementCategory', this.announcementCategoryValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:highlightFilter', this.highlightFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>
