<template>

  <div class="workflow__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ announcementCategory.name }}
            </h1>
            <p class="log_info">
              Created by {{ announcementCategory.createdBy ? announcementCategory.createdBy.name : '' }} on {{ dateFormatWithTime(announcementCategory.createdAt) }} <br>Last updated on {{ dateFormatWithTime(announcementCategory.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="light"
          class="text-primary"
          :to="{ name: 'agencies-announcement-categories-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana Agent SG</strong> This is the control panel where administrators can edit the content for the agent-facing mobile app.</span>
      </div>
    </b-alert>
    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230">
              Category
            </b-th>
            <b-td class="mt-50">
              {{ announcementCategory.name }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Status
            </b-th>
            <b-td class="mt-50">
              <span :class="announcementCategory.status == 'active' ? 'text-success text-capitalize' : 'text-capitalize'">
                {{ announcementCategory.status }}
              </span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <all-announcement />
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBTooltip, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import 'array.prototype.move'
import store from '@/store/index'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import AllAnnouncement from '../announcements/all-announcements/AllAnnouncement.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAlert,

    AllAnnouncement,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      canViewThisAction,
      announcementCategory: {},
      catAnnouncements: [],
    }
  },
  created() {
    this.getAnnouncementCategory()
    // this.getAnnouncements()
  },

  methods: {
    resolveAnnouncementStatusVariant(status) {
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    },

    getAnnouncementCategory() {
      this.$http.get(`customer/announcement-categories/${this.$route.params.id}/show`)
        .then(response => {
          this.announcementCategory = response.data || {}
          this.catAnnouncements = response.data.announcements || []
          store.commit('breadcrumb/REMOVE_BREADCRUMB')
          const newMeta = {
            active: true,
            text: `${this.announcementCategory.name}`,
          }
          store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    getAnnouncements() {
      this.$http.get(`customer/announcement-categories/${this.$route.params.id}/all-announcements`)
        .then(response => {
          this.catAnnouncements = response.data.catAnnouncements || []
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    deleteEntity(id) {
      this.$swal({
        title: 'Delete this Announcement?',
        html: 'Are you sure you want to delete this announcement? This cannot be undone..',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete-yellow.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.delete(`customer/announcements/${id}/delete`)
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message ?? '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.getAnnouncements()
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    moveTask(from, to) {
      this.catAnnouncements.move(from, to)

      const announcementsPriorityArray = []
      this.catAnnouncements.forEach(taskList => {
        announcementsPriorityArray.push(taskList._id)
      })
      const formData = new FormData()

      formData.append('announcementCategory', this.$route.params.id)
      formData.append('announcements', JSON.stringify(announcementsPriorityArray))

      this.$http.patch('customer/announcement-categories/update/order/announcement-list', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          if (response.data.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.announcement__action-btn-grp{
     .btn{
       padding: 0;
       &:hover{
         background-color: transparent;
       }
     }
}
</style>
